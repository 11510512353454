<template>
  <apexchart
    v-if="show"
    type="bar"
    height="300"
    :options="options"
    :series="series"
  ></apexchart>
</template>

<script>
export default {
  props: ['title', 'dataValues', 'legends'],
  created() {
    // change y-axis max values to +5% max to avoid weird labels
    const max = Math.max(...this.dataValues);
    if (max < 100) this.options.yaxis.max = Math.floor(max * 1.2);
    else this.options.yaxis.max = Math.floor(max * 1.05);
    this.setNoDataText();
    this.options.title.style = this.style;
    this.options.noData.style = this.style;
    this.options.noData.style = { fontSize: '12px' };
  },
  data() {
    return {
      style: {
        fontSize: '18px',
        fontWeight: 'bold',
        fontFamily: ['Open Sans', 'Helvetica', 'Arial', 'Sans-Serif'],
        color: '#757575',
      },
      options: {
        chart: {
          id: `${this.title}-charts`,
        },
        title: {
          text: this.title.toUpperCase(),
          align: 'left',
        },
        xaxis: {
          categories: this.legends,
        },
        yaxis: {
          show: false,
        },
        legend: {
          show: false,
        },
        grid: {
          show: false,
        },
        plotOptions: {
          bar: {
            distributed: true,
            borderRadius: 5,
            dataLabels: {
              position: 'top',
            },
          },
        },
        dataLabels: {
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ['#363636'],
          },
        },
        colors: ['#DBB42B'],
        noData: {
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
        },
      },
      series: [
        {
          name: '',
          // chart values
          data: this.dataValues,
        },
      ],
      tooltip: {
        theme: 'dark',
        x: {
          show: false,
        },
        y: {
          title: {
            formatter: function() {
              return '';
            },
          },
        },
      },
      show: true,
    };
  },
  computed: {
    breakpoint() {
      return this.$vuetify.breakpoint.name;
    },
  },
  methods: {
    setNoDataText() {
      if (
        window.innerWidth <= 350 ||
        (this.breakpoint === 'sm' && window.innerWidth <= 650)
      ) {
        this.options.noData.text = 'No results found';
      } else {
        this.options.noData.text =
          'No results found. Please try a different set of filters.';
      }
    },
  },
  watch: {
    breakpoint() {
      this.setNoDataText();
      this.show = false;
      setTimeout(() => {
        this.show = true;
      }, 100);
    },
  },
};
</script>

<style scoped>
.chart-color {
  background-color: #dbb42b;
}
</style>
